function History() {
    this.stack = [];
}

History.prototype.add = function add(history) {
    this.stack.push(history);
    return this.stack.length - 1;
};

History.prototype.remove = function remove(history) {
    var idx = this.stack.lastIndexOf(history);

    if (idx !== -1) {
        this.stack.splice(idx, 1);
    } else {
        console.info("trying to remove history from stack, but it's not in the stack!");
    }
};

History.prototype.removeHistoryAtIndex = function removeHistoryAtIndex(idx) {
    if (this.stack[idx] != null) {
        this.stack.splice(idx, 1);
    } else {
        console.info("trying to remove history at index from stack, but index is out of range!");
    }
};

History.prototype.splice = function splice() {
    return this.stack.splice.apply(this.stack, arguments);
};
/**
 * Adds the screenState provided as the second to last item to the history. So it becomes the new previous
 * @param history
 * @returns {*|[]|*[]}
 */


History.prototype.addAsPrevious = function addAsPrevious(history) {
    return this.stack.splice(this.stack.length - 1, 0, history);
};

History.prototype.current = function current() {
    if (this.stack[this.stack.length - 1]) {
        return this.stack[this.stack.length - 1];
    } else {
        return undefined;
    }
};

History.prototype.previous = function previous() {
    if (this.stack[this.stack.length - 2]) {
        return this.stack[this.stack.length - 2];
    } else {
        return undefined;
    }
};

History.prototype.empty = function empty() {
    while (this.stack.length) {
        this.stack.pop();
    }
};

History.prototype.indexOf = function indexOf(a) {
    return this.stack.lastIndexOf(a);
};

History.prototype.historyAtIndex = function historyAtIndex(idx) {
    return this.stack[idx];
};

History.prototype.count = function count(a) {
    return this.stack.filter(function (v) {
        return v.indexOf(a) >= 0;
    }).length;
};

Object.defineProperty(History.prototype, "length", {
    get: function length() {
        return this.stack.length;
    }
});
