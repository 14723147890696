'use strict';

function ViewStack() {
    this.stack = []; //this.stack = {};
}

ViewStack.prototype.contains = function contains(id) {
    return this._find(id) >= 0; //return !!this.stack[id];
};
/**
 * returns the view at index
 * @param idx
 * @returns {View}
 */


ViewStack.prototype.viewAtIndex = function viewAtIndex(idx) {
    return this.stack[idx].view;
};

ViewStack.prototype.idOfViewAtIndex = function idOfViewAtIndex(idx) {
    if (this.stack.length > idx) {
        return this.stack[idx].id;
    } else {
        return null;
    }
};
/**
 * Find a view by it's ID
 * @param id the id to look for
 * @param [reverse] start from end
 * @param {bool} [keepInStack=false] if the view should be returned and removed from stack
 * @returns view
 */


ViewStack.prototype.viewForID = function viewForID(id, reverse, keepInStack) {
    var idx = reverse ? this._findReverse(id) : this._find(id);

    if (idx >= 0) {
        var result = this.stack[idx].view;
        !keepInStack && this.stack.splice(idx, 1);
        return result;
    } else {
        return null;
    }
    /*
    var view = this.stack[id];
    delete this.stack[id];
    return view;
    */

};
/**
 * Inserts the view with the id provided at the exact index given. does not overwrite, existing content
 * at that position is moved by one.
 * @param idx   where the id&view go
 * @param id    the id of the view
 * @param view  the view itself
 */


ViewStack.prototype.insertView = function insertView(idx, id, view) {
    this.stack.splice(idx, 0, {
        id: id,
        view: view
    });
};

ViewStack.prototype.length = function length() {
    return this.stack.length;
};

ViewStack.prototype.add = function add(id, view) {
    this.stack.push({
        id: id,
        view: view
    }); //this.stack[id] = view;
};

ViewStack.prototype.remove = function remove(id) {
    var idx = this._findReverse(id);

    if (idx >= 0) {
        this.stack.splice(idx, 1);
    } //delete this.stack[id];

};

ViewStack.prototype.pop = function pop() {
    return this.stack.pop().view;
    /*
    var key = Object.keys(this.stack)[0],
        view = this.stack[key];
    delete this.stack[key];
    return view;
    */
};

Object.defineProperty(ViewStack.prototype, "length", {
    get: function length() {
        return this.stack.length; //return Object.keys(this.stack).length;
    }
});

ViewStack.prototype._find = function find(id) {
    for (var i = 0; i < this.stack.length; i++) {
        if (this.stack[i].id === id) {
            return i;
        }
    }

    return -1;
};

ViewStack.prototype._findReverse = function findReverse(id) {
    for (var i = this.stack.length - 1; i >= 0; i--) {
        if (this.stack[i].id === id) {
            return i;
        }
    }

    return -1;
};

ViewStack.prototype.stringify = function stringify() {
    var result = "[";

    for (var i = 0; i < this.stack.length; i++) {
        if (i > 0) {
            result += ",";
        }

        result += '"' + this.stack[i].id + '(viewId:' + this.stack[i].view.viewId + ')"';
    }

    result += "]";
    return result;
};
