'use strict';

window.GUI = function (GUI) {
    var FRAME_DURATION_LIMIT = 5; // 16ms is the most, but there should be time for repaint and so on

    var FRAME_OP_RECHECK_CYCLE = 5;

    class AnimationFrameHandler {
        constructor(element) {
            this.name = "AnimationFrameHandler";
        }

        start() {
            Debug.GUI.AnimationFrame && console.log(this.name, "start");
            this._stopAnimationFrameProcessing = false;

            this._startAnimationFrameTimeout();
        }

        stop() {
            Debug.GUI.AnimationFrame && console.log(this.name, "stop");

            this._stopAnimationFrameHandling();
        }

        flush() {
            if (this._animationFrameOperations && this._animationFrameOperations.length > 0) {
                console.warn("##############" + this.name + "####################");
                console.warn("--------------------------------------------------");
                console.warn("Flush, process remaining frame operations " + this._animationFrameOperations.length);
                console.warn("--------------------------------------------------");
                console.warn("##############" + this.name + "####################");

                this._animationFrameOperations.forEach(function (operation) {
                    operation.domOps.forEach(function (domOp) {
                        domOp();
                    });
                    operation.deferred.resolve();
                });

                this._animationFrameOperations = [];
            } else {
                console.log(this.name, "this._animationFrameOperations was not defined or had no length Object --> " + JSON.stringify(this._animationFrameOperations));
            }
        }

        processSync(value) {
            this._processSyncron = value;
        }

        /**
         * Stores a dom operation to perform in an animation frame, returns a promise which will resolve once the
         * DOM-Operation has been performed on an animation Frame
         * @param domOperationFn    the dom-operation to be performed on an animation frame
         * @returns {*}             the promise that will resolve once the dom operation has been passed.
         * @private
         */
        schedule(domOperationFn) {
            return this.scheduleAll([domOperationFn]);
        }

        /**
         * Stores multiple dom operation to perform in an animation frame, returns a promise which will resolve once the
         * DOM-Operations have been performed on an animation Frame
         * @param domOperationFns   an array of dom-operation fns to be performed on an animation frame
         * @returns {*}             the promise that will resolve once the dom operations have been performed.
         * @private
         */
        scheduleAll(domOperationFns) {
            if (Debug.Test.AF_DISABLED || this._processSyncron) {
                domOperationFns.forEach(function (domOpFn) {
                    domOpFn();
                });
                return Q.resolve();
            }

            var afTaskObj = {
                domOps: domOperationFns,
                deferred: Q.defer()
            };
            this._animationFrameOperations = this._animationFrameOperations || [];

            this._animationFrameOperations.push(afTaskObj);

            return afTaskObj.deferred.promise;
        }

        append(element, target) {
            return this.schedule(function appendAf() {
                target.append(element);
            });
        }

        prepend(element, target) {
            return this.schedule(function prependAf() {
                target.prepend(element);
            });
        }

        insertBefore(element, target) {
            return this.schedule(function insertBeforeAf() {
                element.insertBefore(target);
            });
        }

        insertAfter(element, target) {
            return this.schedule(function insertAfterAf() {
                element.insertAfter(target);
            });
        }

        remove(element) {
            return this.schedule(function removeAf() {
                element.remove();
            });
        }

        replace(toReplace, replacer) {
            return this.schedule(function () {
                toReplace.replaceWith(replacer);
            });
        }

        empty(toEmpty) {
            return this.schedule(function emptyAf() {
                toEmpty.empty();
            });
        }

        hide(element) {
            return this.schedule(function hideAf() {
                element.hide();
            });
        }

        show(element) {
            return this.schedule(function showAf() {
                element.show();
            });
        }

        toggle(element, toggler) {
            return this.schedule(function toggleAf() {
                if (!!toggler) {
                    element.show();
                } else {
                    element.hide();
                }
            });
        }

        setCssAttr(element, cssName, cssVal) {
            return this.schedule(function setCssAttrAf() {
                element.css(cssName, cssVal || ""); // imporant to pass "" instead of undefined, otherwise its interpreted as getter!
            });
        }

        addCssClass(element, cssClass) {
            return this.schedule(function addCssClassAf() {
                element.addClass(cssClass);
            });
        }

        removeCssClass(element, cssClass) {
            return this.schedule(function removeCssClassAf() {
                element.removeClass(cssClass);
            });
        }

        toggleCssClass(element, cssClass, value) {
            return this.schedule(function toggleCssClassAf() {
                element.toggleClass(cssClass, !!value);
            });
        }

        setAttr(element, attrName, attrValue) {
            return this.schedule(function setAttrAf() {
                element.attr(attrName, attrValue);
            });
        }

        setHtml(element, htmlContent) {
            return this.schedule(function setHtmlAf() {
                element.html(htmlContent);
            });
        }

        setText(element, text) {
            return this.schedule(function setTextAf() {
                element.text(text);
            });
        }

        setScrollTop(element, newScrollTop) {
            return this.schedule(function setScrollTopAf() {
                element.scrollTop(newScrollTop);
            });
        }

        /**
         * When called will process all operations within a single animationFrame
         * @returns {Q.Promise<unknown>}
         * @private
         */
        _performAnimationFrameOps() {
            if (!this._animationFrameOperations || this._animationFrameOperations.length === 0) {
                // nothing to do, try again later - if still something left to do.
                this._startAnimationFrameTimeout();

                return Q.resolve();
            } else if (this._animationFrameDef) {
                // currently waiting for a dom operation
                return Q.resolve();
            } // clear, as the call may have been made directly without waiting for a timeout


            if (this._domOpTimeout) {
                clearTimeout(this._domOpTimeout);
                this._domOpTimeout = null;
            }

            Debug.GUI.AnimationFrame && console.log(this.name, "_performAnimationFrameOps: " + this._animationFrameOperations.length);
            this._animationFrameDef = Q.defer();
            var domOpDef = this._animationFrameDef;
            lxRequestAnimationFrame(function acquiredAnimationFrame() {
                var frameStartTs = window.performance.now(),
                    performedFrameOpCnt = 0,
                    domOpStartTs,
                    deltaFrameOp;

                this._animationFrameOperations.every(function animationFrameOperationEvery(afTaskObj, opIdx) {
                    Debug.GUI.AnimationFrame && console.log("     _performingAnimationFrameOp - " + opIdx);
                    performedFrameOpCnt++;
                    domOpStartTs = window.performance.now();
                    afTaskObj.domOps && afTaskObj.domOps.forEach(function afDomOpsIteration(domOp) {
                        try {
                            domOp();
                        } catch (ex) {
                            console.error("AnimationFrameHandler", "----------------");
                            console.error("AnimationFrameHandler", "Failed while performing frameOp: " + ex);
                            console.error("AnimationFrameHandler", afTaskObj);
                            console.error("AnimationFrameHandler", "----------------");
                        }
                    });

                    if (window.performance.now() - domOpStartTs > FRAME_DURATION_LIMIT) {
                        console.warn("DOM Operations performed take too long (" + (window.performance.now() - domOpStartTs) + "ms for " + afTaskObj.domOps.length + " operations) - split up!");
                        console.warn("AnimationFrameHandler", "The task affected: ", afTaskObj);
                    }

                    deltaFrameOp = window.performance.now() - frameStartTs;

                    if (deltaFrameOp > FRAME_DURATION_LIMIT) {
                        Debug.GUI.AnimationFrame && console.warn("     frame op took " + deltaFrameOp + "ms, skip to next");
                        return false;
                    } else {
                        return true;
                    }
                }.bind(this));

                setTimeout(domOpDef.resolve.bind(domOpDef, performedFrameOpCnt), 1);
            }.bind(this));
            return this._animationFrameDef.promise.then(function animationFramePassed(performedFrameOps) {
                Debug.GUI.AnimationFrame && console.log(this.name, " --> Passed _performAnimationFrameOps: " + performedFrameOps);

                var performed = this._animationFrameOperations.splice(0, performedFrameOps);

                performed.forEach(function domOpDefResolver(afTaskObj) {
                    setTimeout(afTaskObj.deferred.resolve, 1); // call outside of animation frame.
                });
                this._animationFrameDef = null;

                if (this._animationFrameOperations.length > 0) {
                    this._performAnimationFrameOps();
                } else {
                    this._startAnimationFrameTimeout();
                }
            }.bind(this));
        }

        _startAnimationFrameTimeout() {
            if (this._stopAnimationFrameProcessing) {
                return;
            }

            this._recheckAnimationFrameOpsTimeout = setTimeout(this._performAnimationFrameOps.bind(this), FRAME_OP_RECHECK_CYCLE);
        }

        _stopAnimationFrameHandling() {
            this._recheckAnimationFrameOpsTimeout && clearTimeout(this._recheckAnimationFrameOpsTimeout);
            this._stopAnimationFrameProcessing = true;
        }

    }

    GUI.AnimationFrameHandler = AnimationFrameHandler;

    var __animationHandler = new GUI.AnimationFrameHandler();

    GUI.animationHandler = __animationHandler;
    return GUI;
}(window.GUI || {});
